import 'lazysizes';
import 'gsap';
import ScreenNavigator from 'screen-navigator';
import {Transitions} from 'screen-navigator';
import ajaxify from 'navigation/ajaxify';
import nav from 'components/nav';
import navToggleBtn from 'components/navToggleBtn';
import logo from 'components/logo';
import footer from 'components/footer';
import urlQuery from 'url-query';
import loading from 'components/loading';
import getIEVersion from 'utils/browser/ie-version';
import getOrientation from 'utils/screen/get-orientation';

const PAGES_CLASSES = {
  'landing': require('pages/Landing').default,
  'default': require('pages/BasePage').default,
  'home': require('pages/Home').default,
  'about': require('pages/About').default,
  'projects': require('pages/Projects').default,
  'project': require('pages/Project').default,
};

var body;
var pageNavigator;
var nextPage;
var isFirstPage = true;

(() => {
  app = window.app || {};

  // screen sizes
  app.screenXS = 480;
  app.screenS = 768;
  app.screenM = 1024;
  app.screenL = 1280;
  app.screenXL = 1440;
  app.screenXXL = 1600;

  // size
  app.windowWidth = app.windowHeight = 0;
  app.clientWidth = 0;
  app.prevWindowWidth = 0;
  app.orientation = app.prevOrientation = null;

  // capabilities
  app.hasHistory = Modernizr.history;
  app.hasTouch = Modernizr.touchevents;

  // ajax
  app.ajaxEnabled = app.hasHistory;
  // app.ajaxEnabled = false;

  // url
  app.urlQuery = window.location.search ? urlQuery() : {};

  // browser
  app.isIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0;
  app.IEVersion = getIEVersion();
  app.isIE10 = app.IEVersion.major === '10';
  app.isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;

  // Prevent scroll jump on page navigation back/forward
  // /!\ History API experimental feature
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  // add app class
  $('html').removeClass('no-app').addClass('app');

  body = $('body');

  $(window).on('resize', onResize);
  onResize();

  initPageNavigator();
  initAjaxify();
  initNav();
  initNavToggleBtn();
  initLogo();

  if (app.debug && app.urlQuery.skip){
    loadFirstPage();
  }else{
    showLanding();
  }
})();

function initAjaxify(){
  if (!app.ajaxEnabled) return;

  ajaxify.init();
  
  ajaxify.on('popState', onAjaxifyPopState);
  ajaxify.on('loading', onAjaxifyLoading);
  ajaxify.on('loaded', onAjaxifyLoaded);
}

function initPageNavigator(){
  pageNavigator = new ScreenNavigator();

  pageNavigator.on('transitionStart', onPageNavigatorTransitionStart);
  pageNavigator.on('transitionComplete', onPageNavigatorTransitionComplete);
}

function initNav() {
  nav.on('linkClick', onLinkClick);
  nav.on('visibilityChange', onNavVisibilityCHange);
}

function initNavToggleBtn() {
  navToggleBtn.on('click', onNavToggleBtnClick);
  navToggleBtn.on('rollOver', onNavToggleBtnRollOver);
}

function initLogo(){
  logo.on('linkClick', onLinkClick);
}

function showLanding(){
  const pageId = 'landing';
  const pageClass = PAGES_CLASSES[pageId];

  pageNavigator.addItem(pageId, pageClass);

  pageNavigator.showScreen(pageId, Transitions.in, {
    arguments: [{
      id: pageId
    }],
    events: {
      animateInComplete: onLandingAnimateInComplete,
      complete: onLandingComplete
    }
  });
}

function showAjaxLoading(){
  body.addClass('ajax-loading');

  loading.show();
}

function hideAjaxLoading(){
  body.removeClass('ajax-loading');

  loading.hide();
}

function loadFirstPage(){
  nextPage = {
    element: $('.page')[0]
  };
  
  loadNextPage();
}

function loadNextPage(){
  var element = nextPage.element || $(nextPage.data)[0];
  var pageClassId = element.id.split('-page')[0];
  var pageId = element.id;

  if (!PAGES_CLASSES[pageClassId]){
    // we have to search for the page class name in the element classes
    var classNames = element.className.split(' ');

    for (var i = 0, n = classNames.length; i < n; i++){
      var className = classNames[i];

      if (className === 'page') continue;

      if (className.indexOf('-page') !== -1){
        className = className.replace('-page', '');
      }

      if (PAGES_CLASSES[className]){
        pageClassId = className;
        break;
      }
    }
  }

  var item = pageNavigator.getItem(pageId);
  var currentPage = pageNavigator.currentScreen;
  var prevScreenData = currentPage ? currentPage.screenData : null;

  if (!item){
    var pageClass = PAGES_CLASSES[pageClassId] || PAGES_CLASSES['default'];

    item = pageNavigator.addItem(pageId, pageClass, {
      events: {
        linkClick: onLinkClick,
      }
    });
  }

  item.setOptions({
    canDispose: false,
    arguments: [{
      id: pageId,
      classId: pageClassId,
      element,
      owner: pageNavigator,
      isAjax: !!nextPage.url,
      prevScreenData,
    }]
  });

  nextPage.instance = item.getScreen();

  nextPage.instance.on('assetsLoaded', onPageAssetsLoaded);

  nextPage.instance.init();
  nextPage.instance.loadAssets();
}

function abortLoadNextPage(){
  if (nextPage.instance){
    nextPage.instance.abortLoadAssets();
    
    disposeNextPage(true);
  }

  nextPage = null;
}

function showNextPage(){
  var currentPage = pageNavigator.currentScreen;
  var prevScreenData = currentPage ? currentPage.screenData : null;
  var transition = Transitions.outAndIn;

  if (currentPage){
    if (
      (currentPage.classId === 'home' && nextPage.instance.classId === 'projects') ||
      (currentPage.classId === 'home' && nextPage.instance.classId === 'about') ||
      (currentPage.classId === 'projects' && nextPage.instance.classId === 'about') ||
      (currentPage.classId === 'projects' && nextPage.instance.classId === 'project') ||
      (currentPage.classId === 'project' && nextPage.instance.classId === 'project') ||
      (currentPage.classId === 'project' && nextPage.instance.classId === 'projects') ||
      (currentPage.classId === 'project' && nextPage.instance.classId === 'about') ||
      (currentPage.classId === 'about' && nextPage.instance.classId === 'projects') ||
      (currentPage.classId === 'about' && nextPage.instance.classId === 'project') ||
      (currentPage.classId === 'home' && nextPage.instance.classId === 'project' && !nextPage.instance.isOnHome) ||
      (currentPage.classId === 'project' && nextPage.instance.classId === 'home' && !currentPage.isOnHome) ||
      (currentPage.id !== 'landing' && app.windowWidth < app.screenS)
    ){
      transition = Transitions.outThenIn;
    }
  }
  
  pageNavigator.showScreen(nextPage.instance.id, transition, {
    canDispose: true,
  });

  disposeNextPage();
}

function disposeNextPage(disposeScreen = false){
  nextPage.instance.off('assetsLoaded', onPageAssetsLoaded);

  if (disposeScreen){
    nextPage.instance.dispose();
  }

  nextPage = null;
}

function onLinkClick(event) {
  if (!ajaxify.initialized) return;

  ajaxify.onLinkClick(event);
}

function onAjaxifyPopState(state){
  var currentPage = pageNavigator.currentScreen;

  if (currentPage.id !== 'landing'){
    pageNavigator.currentScreen.onPopState(event);
  }
}

function onAjaxifyLoading(url){
  var currentPage = pageNavigator.currentScreen;

  nav.hide();

  if (nextPage){
    abortLoadNextPage();
  }

  showAjaxLoading();
}

function onAjaxifyLoaded(data) {
  if (data.status !== 200 && data.status !== 404){
    location.href = app.baseUrl;
    return;
  }

  hideAjaxLoading();

  // var element = $(data.pageData)[0];

  nextPage = {
    data: data.pageData,
    // element: element,
    url: data.url
  };

  if (pageNavigator.transitionRunning){
    return;
  }

  loadNextPage();
}

function onPageNavigatorTransitionStart(){
  var currentPage = pageNavigator.currentScreen;
  var previousPage = pageNavigator.previousScreen;

  if (currentPage.id === 'landing'){
    return;
  }

  nav.hide();
  nav.updateSelectedItem(currentPage.id);

  if (isFirstPage){
    isFirstPage = false;

    logo.animateIn();
    navToggleBtn.animateIn();
  }else{
    var navToggleLabel = currentPage.element.attr('data-toggle-nav-label');

    navToggleBtn.updateLabel(navToggleLabel);
  }
}

function onPageNavigatorTransitionComplete(){
  var currentPage = pageNavigator.currentScreen;

  if (currentPage && currentPage.id === 'landing'){
    return;
  }

  if (nextPage){
    loadNextPage();
  }
}

function onPageAssetsLoaded(){
  var currentPage = pageNavigator.currentScreen;

  if (currentPage && currentPage.id === 'landing' && !currentPage.isComplete){
    return;
  }

  showNextPage();
}

function onLandingAnimateInComplete(){
  loadFirstPage();
}

function onLandingComplete(){
  if (nextPage.instance.isLoading) return;

  showNextPage();
}

function onNavToggleBtnClick(){
  nav.toggle();
}

function onNavToggleBtnRollOver(){
  nav.show();
}

function onNavVisibilityCHange(){
  navToggleBtn.setState(nav.isVisible);
}

function onResize(event){
  if (app.windowWidth > 0){
    app.prevWindowWidth = app.windowWidth;
  }

  if (app.orientation){
    app.prevOrientation = app.orientation;
  }
  
  app.windowWidth = window.innerWidth;
  app.windowHeight = window.innerHeight;

  app.orientation = getOrientation();
  
  app.clientWidth = document.body.clientWidth;

  if (pageNavigator && pageNavigator.currentScreen){
    pageNavigator.currentScreen.resize(event);
  }
}

